import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { symbols } from '../../../../../../apps/viewer/src/app/app.keys'; // FIXME: Angular 7.0 runtime i18n

/**
 * Pipe to transform keyboard event codes to HTML keyboard input tags.
 */
@Pipe({
  name: 'key'
})
export class KeyPipe implements PipeTransform {

  /**
   * Constructor of the pipe.
   *
   * @param sanitizer Sanitizes a value for use in the given SecurityContext.
   */
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Transforms keyboard event codes to HTML keyboard input tags.
   *
   * @param value Keyboard event code.
   */
  transform(value: any): SafeHtml {
    const parts: string[] = value.split('.').map(key => `<kbd>${symbols[key] || key}</kbd>`);
    return this.sanitizer.bypassSecurityTrustHtml(parts.join(' + '));
  }

}
