import { Component, Inject, Input } from '@angular/core';

import { WINDOW } from '@page2flip/core';

/**
 * Component that represents the loader overlay.
 */
@Component({
  selector: 'p2f-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  /** Loader message. */
  @Input() message: string;

  /** Loader percentage. */
  @Input() percentage: string;

  /**
   * Constructor of the component.
   *
   @param window  DI Token for the Window object.
   */
  constructor(@Inject(WINDOW) private window: any) {}

}
