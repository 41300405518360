import Timer = NodeJS.Timer;

/**
 * Decorator for debouncing method calls.
 *
 * @param delay The delay in ms.
 */
export function debounce(delay: number = 250): MethodDecorator {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const descriptorValue = descriptor.value;
    let timeout: Timer;

    descriptor.value = function(...args) {
      clearTimeout(timeout);
      timeout = <Timer>setTimeout(() => descriptorValue.apply(this, args), delay);
    };

    return descriptor;
  };
}
