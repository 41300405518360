import { Observable, Subject } from 'rxjs';

export class PerfectScrollbarService {

  /** Subject that control the Perfect Scrollbar instance. */
  private _update: Subject<void> = new Subject<void>();

  /**
   * Updates the Perfect Scrollbar instance.
   */
  doUpdate() {
    this._update.next();
  }

  /**
   * Gets an observable that is notified when the Perfect Scrollbar instance is updated.
   */
  update(): Observable<void> {
    return this._update.asObservable();
  }

}
