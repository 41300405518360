import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Service to control the loader component.
 */
@Injectable()
export class LoaderService {

  /** Subject that controls the loading. */
  private readonly _loading: Subject<boolean> = new Subject<boolean>();

  /**
   * Gets an observable that is notified when loading starts or stops.
   */
  loading(): Observable<boolean> {
    return this._loading.asObservable();
  }

  /**
   * Notifies observers that loading has started.
   */
  start() {
    this._loading.next(true);
  }

  /**
   * Notifies observers that loading has stopped.
   */
  stop() {
    this._loading.next(false);
  }

}
