import { NgModule } from '@angular/core';

import { DataService } from './services/data.service';
import { HotspotService } from './services/hotspot.service';
import { PerfectScrollbarService } from './services/perfect-scrollbar.service';
import { UploaderService } from './services/uploader.service';

@NgModule({
  providers: [
    DataService,
    HotspotService,
    PerfectScrollbarService,
    UploaderService
  ]
})
export class CoreModule {}
